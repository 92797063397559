import { withInstall } from 'framework/utils'
import appSiteSelect from './src/AppSiteSelect.vue'
import appSiteTabs from './src/AppSiteTabs.vue'
import { useComponentRegister } from 'framework/components/Form/index'

// 将admin的component注入到 framenwork的Form组件中
useComponentRegister('AppSiteSelect', appSiteSelect)

export const AppSiteSelect = withInstall(appSiteSelect)
export const AppSiteTabs = withInstall(appSiteTabs)
